div.ChordIdentification-content {
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  width: 370px;
  margin-left: auto;
  margin-right: auto;
  min-height: 400px;
  padding-top: 10px;
}

.ChordIdentification-progression {
  display: inline-block;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 13px;
  height: 2px;
  width: 15px;
  padding: 2px;
  margin: 2px;
}

.ChordIdentification-progression.correct {
  background-color: chartreuse;
  /* Add the blur effect */
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.ChordIdentification-progression.incorrect {
  background-color: orangered;
  /* Add the blur effect */
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.ChordIdentification-choice-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  color: #fff;
  background-image: -webkit-linear-gradient(45deg, #ffc107 0%, #ff8b5f 100%);
  background-image: linear-gradient(45deg, #ffc107 0%, #ff8b5f 100%);
  transition: 0.4s;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
  border: none;
  margin: 5px;
  width: 150px;
  bottom: 5px;
}

.ChordIdentification-choice-button:hover {
  background-image: -webkit-linear-gradient(45deg, #ffc107 0%, #f76a35 100%);
  background-image: linear-gradient(45deg, #ffc107 0%, #f76a35 100%);
  cursor: pointer;
}

.ChordIdentification-choice-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.ChordIdentification-controlButtons {
  color: white;
  background: rgba(0, 0, 0, 0);
  border: 2px solid rgb(255, 255, 255);
  margin: 90px 5px;
  width: 150px;
  height: 60px;
  padding: 10px 20px;
}

/* Add this to align vertically */
.ChordIdentification-controlButtons > canvas,
.ChordIdentification-controlButtons > span {
  vertical-align: middle;
}

.ChordIdentification-controlButtons:hover {
  cursor: pointer;
}

.ChordIdentification-controlButtons:disabled {
  color: rgb(90, 90, 90);
  border: 2px solid rgb(90, 90, 90);
  cursor: not-allowed;
}

.ChordIdentification-close {
  color: white;
  text-align: right;
  font-size: 20px;
}

.ChordIdentification-close > span {
  cursor: pointer;
  margin: 10px;
}

.AnswerPanel {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 13px;
  height: 20px;
  width: 100px;
  padding: 2px;
  margin: 2px;
  color: white;
  margin: auto;
}

.AnswerPanel.correct {
  background-color: #53a602;
}

.AnswerPanel.incorrect {
  background-color: #cc3838;
}
