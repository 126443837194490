body {
  background: url(./images/background.jpg);
  background-size: 100% auto;
  text-align: center;
}

div.Menu-content {
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  width: 370px;
  margin-left: auto;
  margin-right: auto;
  min-height: 400px;
  padding-top: 10px;
  color: #fff;
}

.Start-button {
  display: inline-block;
  background-image: -webkit-linear-gradient(45deg, #ffc107 0%, #ff8b5f 100%);
  background-image: linear-gradient(45deg, #ffc107 0%, #ff8b5f 100%);
  color: #ffffff;
  text-align: center;
  padding: 10px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  border-radius: 25px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
  border: none;
}

.Start-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.Start-button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.Start-button:hover span {
  padding-right: 25px;
}

.Start-button:hover span:after {
  opacity: 1;
  right: 0;
}

.Menu-content > p {
  padding-top: 60px;
  padding-bottom: 70px;
  font-style: italic;
  color: rgb(185, 185, 185);
}

a:link,
a:visited {
  font-style: italic;
  color: rgb(167, 167, 167);
  text-decoration: none;
  border-bottom: 1px rgb(167, 167, 167) dotted;
}
