.HallOfFame-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  color: #fff;
  background-image: -webkit-linear-gradient(45deg, #ffc107 0%, #ff8b5f 100%);
  background-image: linear-gradient(45deg, #ffc107 0%, #ff8b5f 100%);
  transition: 0.4s;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
  border: none;
  margin: 5px;
  width: 150px;
  bottom: 5px;
}

.HallOfFame-button:hover {
  background-image: -webkit-linear-gradient(45deg, #ffc107 0%, #f76a35 100%);
  background-image: linear-gradient(45deg, #ffc107 0%, #f76a35 100%);
  cursor: pointer;
}

.HallOfFame-content {
  color: #fff;
}

.HallOfFame-stars {
  color: rgb(167, 165, 165);
  padding-bottom: 50px;
  font-size: 40px;
}

.HallOfFame-stars > .checked {
  color: orange;
}
